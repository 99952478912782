// @ts-strict-ignore
import React, { memo } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { ErrorFallbackIcon } from '@/core/ErrorFallback.organism';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { Placement } from 'react-bootstrap/esm/Overlay';

// TODO: CRAB-26221
// const DEFAULT_TOOL_TIP_DELAY = 0;

export type IconType =
  | 'theme'
  | 'white'
  | 'dark-gray'
  | 'darkish-gray'
  | 'text-darkest-gray'
  | 'gray'
  | 'color'
  | 'info'
  | 'text'
  | 'warning'
  | 'inherit'
  | 'danger'
  | 'theme-light'
  | 'success';

export interface IconProps {
  /** icon class to be used (i.e. fc-zoom) */
  icon: string;
  /** icon color option (text, white, theme, color, inherit; default is "theme") */
  type?: IconType;
  /** used to add a custom color to the icon (required if type="color") */
  color?: string;
  /** function to call when clicking the icon (takes no parameters) */
  onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => any;
  /** extra class names to be placed on the Icon component */
  extraClassNames?: string;
  /** id that can be placed on the Icon component */
  id?: string;
  /** true to add the 'fa-lg' class to the icon */
  large?: boolean;
  /** true to add the 'fa-sm' class to the icon */
  small?: boolean;
  /** id that will be used in the data-testid attribute on the icon */
  testId?: string;
  /** id that will be used in the data-customid attribute on the icon.
   * Can be used to identify the icon as the click event target in an event handler */
  customId?: string;
  /** text to display on icon tooltip */
  tooltip?: string;
  /** formatted text to display on icon tooltip */
  formattedTooltip?: any;
  /** number of milliseconds to wait before showing a tooltip on-hover */
  tooltipDelay?: number;
  tooltipPlacement?: Placement;
  number?: number;
  /** set this to true if the icon is inside a <SingletonTooltip> element. In this case the tooltip and placement
   * information will be passed on as data attributes and a singleton tooltip element will be reused for this icon.
   * This is recommended for improved performance when there are many icons with tooltips. */
  hasExternalTooltipHandler?: boolean;
}

/**
 * Icon (to be used for icons, i.e. <i> tags)
 */
const IconFC: React.FunctionComponent<IconProps> = ({
  onClick,
  icon,
  type = 'theme',
  extraClassNames,
  id,
  large,
  small,
  color,
  testId,
  customId,
  tooltip,
  formattedTooltip,
  tooltipDelay,
  tooltipPlacement,
  number,
  hasExternalTooltipHandler = false,
}) => {
  if ((type === 'color' && _.isUndefined(color)) || (color && type !== 'color')) {
    const errorMessage = _.isUndefined(color)
      ? 'Icon with type="color" must have prop color specified.'
      : 'Icon with prop color must have type="color".';
    return <ErrorFallbackIcon error={new Error(errorMessage)} retry={null} />;
  }

  const iconPrefix = _.includes(icon, 'fc-') ? 'fc' : 'fa';
  const iconColor = type === 'inherit' ? '' : `sq-icon-${type}`;
  const iconCustomColor = { color };

  const iconTag = (
    <i
      className={classNames(iconPrefix, icon, iconColor, extraClassNames, {
        'fa-lg': large,
        'fa-sm': small,
        'cursorPointer': _.isFunction(onClick) || !!tooltip,
      })}
      style={color && iconCustomColor}
      onClick={onClick}
      data-testid={testId}
      data-customid={customId}
      id={id}
      data-number={number}
      data-tooltip-text={hasExternalTooltipHandler ? tooltip : null}
      data-tooltip-placement={hasExternalTooltipHandler ? tooltipPlacement : null}
    />
  );

  return !hasExternalTooltipHandler && (tooltip || formattedTooltip) ? (
    <HoverTooltip
      text={tooltip}
      formattedText={formattedTooltip}
      delay={tooltipDelay}
      placement={tooltipPlacement || 'auto'}>
      <span>{iconTag}</span>
    </HoverTooltip>
  ) : (
    iconTag
  );
};

export const Icon = memo(IconFC);
/**
 * Help icon
 */
export const HelpIcon: React.FunctionComponent<Omit<IconProps, 'icon' | 'large'>> = (props) => {
  return <Icon {...props} icon="fa-question-circle" large={true} />;
};
