// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { Paginator } from '@/core/Paginator.molecule';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { MIN_HOME_SCREEN_ITEM_COUNT } from '@/homescreen/homescreen.constants';

interface PaginationProps {
  loadTable: (offset: number, limit: number, searchParams?) => void;
  store: PaginationAndFilteringSupportingStore;
  actions: PaginationAndFilteringSupportingActions;
  tableType?: string;
  insideModal?: boolean;
}

export type PaginationAndFilteringSupportingStore = {
  getItemTotalForTable: (table?: string) => number;
  getPageSizeByTable: (table?: string) => number;
  getPageNumberForTable: (table?: string) => number;
  searchParams: object;
  sortProperty?: string;
  sortAscending?: boolean;
};

export type PaginationAndFilteringSupportingActions = {
  setPageSizeForTable: ({ size, table }: { size: number; table: string }) => void;
  setPageNumberAndGo: (page: number, loadTable?: Function, table?: string) => void;
};

export const Pagination: React.FunctionComponent<PaginationProps> = ({
  store,
  actions,
  tableType,
  loadTable,
  insideModal,
}) => {
  const totalResults = useFluxPath(store, () => store.getItemTotalForTable(tableType));
  const tablePageSize = useFluxPath(store, () => store.getPageSizeByTable(tableType));
  const pageNumber = useFluxPath(store, () => store.getPageNumberForTable(tableType));
  const pageCount = _.max([Math.ceil(totalResults / tablePageSize), 1]);

  const setPageSizeInStoreAndTable = (value) => {
    actions.setPageSizeForTable({ size: value, table: tableType });
    actions.setPageNumberAndGo(1, loadTable, tableType);
  };

  return totalResults > MIN_HOME_SCREEN_ITEM_COUNT ? (
    <Paginator
      gotoPage={(number) => actions.setPageNumberAndGo(number, loadTable, tableType)}
      pageCount={pageCount}
      pageNumber={pageNumber}
      pageSize={tablePageSize}
      insideModal={insideModal}
      setPageSize={setPageSizeInStoreAndTable}
    />
  ) : null;
};

export default Pagination;
