// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/Overlay';
import { OverlayTriggerType } from 'react-bootstrap/esm/OverlayTrigger';
import { KEY_CODES } from '@/main/app.constants';
import { useElementHasMoved } from '@/core/hooks/useElementHasMoved.hook';

export type PopoverConfig = {
  id: string;
  placement: Placement;
  trigger?: OverlayTriggerType;
  wrapperClassNames?: string;
  contentClassNames?: string;
};

interface ButtonWithPopoverProps {
  /** used for the button that triggers the dropdown/popover */
  label: React.ReactNode;
  /** content of dropdown/popover */
  children: React.ReactNode;
  /** config props for popover */
  popoverConfig: PopoverConfig;
  /** true if the button should be disabled */
  disabled?: boolean;
  /** true if tooltip should show even if button is disabled */
  alwaysShowTooltip?: boolean;
  /** extra class names to be placed on the label wrapper */
  extraClassNames?: string;
  /** function to call when dropdown/popover is toggled */
  onToggle?: (nextShow?: boolean) => void;
  /** true if dropdown/popover should close when clicked. defaults to true */
  closeOnClick?: boolean;
  tabIndex?: number;
  /** true to call e.stopPropagation() when clicking on the trigger */
  stopPropagation?: boolean;
  /** false to prevent the dropdown from closing if the button is moved (e.g. scrolled into new location) */
  closeWhenMoved?: boolean;
}

/**
 * A button with a dropdown. Content for the dropdown menu should be provided as children to the component.
 * This component combines the best of `ButtonWithPopoverDropdown` and `ButtonWithDropdown` which have both been removed
 */
export const ButtonWithPopover: React.FunctionComponent<ButtonWithPopoverProps> = ({
  label,
  children,
  popoverConfig,
  disabled,
  alwaysShowTooltip,
  extraClassNames,
  onToggle,
  closeOnClick = true,
  closeWhenMoved = true,
  stopPropagation = false,
}) => {
  const [show, setShow] = useState<boolean>();
  const ref = useRef(null);

  useEffect(() => {
    setShow(disabled ? false : undefined);
  }, [disabled]);

  // Ensure that show is of boolean type
  useEffect(() => {
    if (typeof show === 'boolean' && !show) {
      setShow(undefined);
    }
  }, [show]);

  const onClickPopover = (e) => {
    if (closeOnClick) {
      setShow(false);
    }
    if (stopPropagation) {
      e.stopPropagation();
    }
  };

  useElementHasMoved(
    ref,
    () => {
      setShow(false);
    },
    closeWhenMoved && !!show,
  );

  return (
    <OverlayTrigger
      trigger={popoverConfig.trigger || 'click'}
      rootClose={true}
      placement={popoverConfig.placement}
      flip={true}
      transition={false}
      show={show}
      onToggle={(show) => {
        onToggle?.(show);
        if (closeWhenMoved) {
          // We have to set show here to keep our show state in sync with that of the OverlayTrigger.
          // This ensures that the component will see a change and update when the useElementHasMoved()
          // hook triggers and sets show to false.
          setShow(show);
        }
      }}
      overlay={
        <Popover
          id={popoverConfig.id}
          className={classNames(popoverConfig.wrapperClassNames, 'dropdown-menu noArrow pt5 pb5')}
          onClick={onClickPopover}>
          <Popover.Content
            className={classNames('forceZeroPadding', popoverConfig.contentClassNames)}
            data-testid={`${popoverConfig.id}Content`}>
            {children}
          </Popover.Content>
        </Popover>
      }>
      <div
        ref={ref}
        onKeyDown={(e) => {
          if (e.keyCode === KEY_CODES.UP_ARROW || e.keyCode === KEY_CODES.DOWN_ARROW) {
            e.currentTarget.click();
          }
        }}
        onClick={(e) => stopPropagation && e.stopPropagation()}
        className={classNames('cursorPointer', extraClassNames, {
          disabled,
          disabledBehavior: disabled && !alwaysShowTooltip,
        })}>
        {label}
      </div>
    </OverlayTrigger>
  );
};
