// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { Form } from 'react-bootstrap';
import { Checkbox } from '@seeqdev/qomponents';
import { useTranslation } from 'react-i18next';

export interface HeaderOptionsSelectorProps {
  options: { label: string; id: string; value: string }[];
  onChange: (value) => void;
  name: string;
  title?: string;
  defaultValue?: string;
}

export const HeaderOptionsSelector: React.FunctionComponent<HeaderOptionsSelectorProps> = ({
  options,
  onChange,
  name,
  title = '',
  defaultValue,
}) => {
  const { t } = useTranslation();
  return (
    <Form.Group>
      {title !== '' && <Form.Label className="mt15">{title}</Form.Label>}
      {_.map(options, (singleOption) => (
        <Checkbox
          key={singleOption.id}
          type="radio"
          id={singleOption.id}
          name={name}
          testId={singleOption.value}
          label={t(singleOption.label)}
          value={singleOption.value}
          defaultChecked={defaultValue === singleOption.value}
          onChange={() => onChange(singleOption.value)}
        />
      ))}
    </Form.Group>
  );
};
