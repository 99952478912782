// @ts-strict-ignore
import React from 'react';
import ReactPaginate from 'react-paginate';
import { Select } from '@seeqdev/qomponents';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Icon } from '@/core/Icon.atom';

interface PaginatorProps {
  gotoPage: (page: number) => void;
  pageCount: number;
  pageNumber: number;
  pageSize?: number;
  setPageSize?: (size: number) => void;
  insideModal?: boolean;
  total?: number;
  pageSizeOptionsCustom?: number[];
  small?: boolean;
}

export const Paginator: React.FunctionComponent<PaginatorProps> = ({
  gotoPage,
  pageCount,
  pageNumber,
  pageSize,
  setPageSize = _.noop,
  insideModal,
  total,
  pageSizeOptionsCustom,
  small = false,
}) => {
  const { t } = useTranslation();

  const hasPageSize = !_.isNil(pageSize);
  const pageSizeOptions = hasPageSize ? pageSizeOptionsCustom ?? [5, 10, 15, 20, 50] : [];
  const formattedOptions = _.map(pageSizeOptions, (size) => ({
    label: size,
    value: size,
  }));
  const formattedGoToPageOptions = _.map(_.range(1, pageCount + 1, 1), (size) => ({ label: size, value: size }));

  return (
    <div className="paginatorWrapper">
      <div
        className={classNames(
          'paginator',
          'flexColumnContainerWrap',
          'flexAlignCenter',
          small ? 'mr5' : 'mr10',
          insideModal ? 'flexSpaceBetween' : 'pull-right',
        )}
        data-testid="paginator">
        <div className={classNames('flexColumnContainer', small ? 'ml5' : 'ml10')}>
          <div id="goToPageWrapper" data-testid="goToPageWrapper" className="flexColumnContainer flexAlignCenter">
            {total && (
              <span className="pr10">
                {t('PAGINATOR.TOTAL')}
                <strong>{total}</strong>
              </span>
            )}
            <span className="pr5">{t('PAGINATOR.PAGE')}</span>
            <Select
              small={small}
              placeholder=""
              menuPortalTarget={document.body}
              extraClassNames={classNames(small ? 'width-55' : 'width-75', small ? 'pr5' : 'pr10')}
              value={_.find(formattedGoToPageOptions, { value: pageNumber })}
              options={formattedGoToPageOptions}
              isSearchable={true}
              onChange={(option: { value: number }) => gotoPage(option.value)}
            />
          </div>
          {hasPageSize && (
            <div id="itemCountWrapper" data-testid="itemCountWrapper" className="flexColumnContainer flexAlignCenter">
              <span className={classNames(small ? '' : 'pl10')}>{t('PAGINATOR.SHOW')}</span>
              <Select
                small={small}
                menuPlacement="auto"
                menuPortalTarget={document.body}
                extraClassNames={classNames(small ? 'width-55' : 'width-75', 'pl5', small ? '' : 'pr10')}
                value={_.find(formattedOptions, { value: pageSize })}
                options={formattedOptions}
                isSearchable={false}
                onChange={(option: { value: unknown }) => setPageSize(option.value)}
              />
            </div>
          )}
        </div>
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={insideModal ? 1 : 2}
          marginPagesDisplayed={insideModal ? 1 : 2}
          previousLabel={insideModal ? <Icon icon="fa-caret-left" /> : t('PAGINATOR.PREVIOUS')}
          nextLabel={insideModal ? <Icon icon="fa-caret-right" /> : t('PAGINATOR.NEXT')}
          breakLabel={t('PAGINATOR.BREAK')}
          forcePage={pageNumber - 1}
          onPageChange={(data) => gotoPage(data.selected + 1)}
          breakClassName="break-me"
          containerClassName={classNames(
            'pagination',
            'sq-btn-group',
            small ? 'pagination-small' : 'pagination-lg height-34',
          )}
          subContainerClassName="pages pagination"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
          disabledClassName="disabledLook disabledBehavior"
        />
      </div>
    </div>
  );
};
