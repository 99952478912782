import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/core/Icon.atom';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { doTrack } from '@/track/track.service';
import { setAdvancedParametersCollapsedState } from '@/toolSelection/investigate.actions';

export type ToolStore = {
  advancedParametersCollapsed: boolean;
};

interface AdvancedParametersProps {
  toolId: string;
  toolName: string;
  alternateName?: string;
  toolStore: ToolStore;
  children?: ReactNode;
}

export const AdvancedParameters: React.FunctionComponent<AdvancedParametersProps> = (props) => {
  const { toolName, toolId, toolStore, alternateName = undefined, children } = props;
  const { t } = useTranslation();

  const collapsed = useFluxPath(toolStore, () => toolStore.advancedParametersCollapsed);

  const trackAndClick = () => {
    doTrack(toolName, 'Advanced Toggle', `Advanced ${collapsed ? 'expanded' : 'collapsed'}`);
    setAdvancedParametersCollapsedState(toolId, !collapsed);
  };

  return (
    <AdvancedSection
      collapsed={collapsed}
      alternateName={alternateName}
      trackAndClick={trackAndClick}
      children={children}
      t={t}
    />
  );
};

interface AdvancedSectionProps {
  collapsed: boolean;
  alternateName?: string;
  trackAndClick?: React.MouseEventHandler<HTMLDivElement>;
  children?: React.ReactNode;
  t: Function;
}

export const AdvancedSection: React.FunctionComponent<AdvancedSectionProps> = ({
  collapsed,
  alternateName,
  trackAndClick,
  children,
  t,
}) => {
  return (
    <div id="advancedParameters">
      <div data-testid="panelControl" className="cursorPointer pt5 pb5 sq-text-primary" onClick={trackAndClick}>
        <Icon icon={collapsed ? 'fa-plus' : 'fa-minus'} testId="advancedParametersIcon" />
        <a className="ml10 link-no-underline">{t(alternateName ? alternateName : 'ADVANCED')}</a>
      </div>
      {!collapsed && (
        <>
          <div className="divider" />
          {children}
        </>
      )}
    </div>
  );
};
