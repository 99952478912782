// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Form } from 'react-bootstrap';
import utilities from '@/core/utilities';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { KEY_CODES } from '@/main/app.constants';
import { Portal } from './Portal.molecule';

interface EditableTextProps {
  value: string;
  onUpdate: (newText: string) => void;
  maxDisplayChars?: number;
  id?: string;
  testId?: string;
  forceEdit?: boolean;
  autoWidth?: boolean;
  allowEditing?: boolean;
  allowEmptyValue?: boolean;
  textClasses?: string; // extra classes to add to the text element (while not in editing mode)
  inputClasses?: string; // extra classes to add to the input element (while in editing mode)
}

/**
 * Input field that displays as text until clicked (see usage, e.g. DateTimeEntry)
 */
export const EditableText: React.FunctionComponent<EditableTextProps> = (props) => {
  const {
    value = '',
    autoWidth = false,
    onUpdate,
    textClasses,
    inputClasses,
    id,
    testId,
    allowEditing = true,
    allowEmptyValue = false,
    forceEdit = undefined,
    maxDisplayChars = -1,
  } = props;

  const [inputWidth, setInputWidth] = useState<number>();
  const [inputHeight, setInputHeight] = useState<number>();
  const [showInput, setShowInput] = useState<boolean>();
  const [newValue, setNewValue] = useState(value);
  const heightRef = useRef<HTMLSpanElement>();
  const inputRef = useRef<HTMLDivElement>();

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  useEffect(() => {
    if (autoWidth && inputRef.current) {
      setInputWidth(inputRef.current.clientWidth);
    }
  }, [newValue]);

  useEffect(() => {
    if (!_.isUndefined(forceEdit) && allowEditing) {
      setShowInput(forceEdit);
    }
  }, [forceEdit]);

  function setInputSize(target: HTMLElement) {
    setInputHeight(heightRef.current.clientHeight);
    setInputWidth(target.clientWidth);
  }

  const updateInput = () => {
    if (value !== newValue && (!_.isEmpty(newValue) || (_.isEmpty(newValue) && allowEmptyValue))) {
      onUpdate(newValue);
      // If the update fails and the value stays the same, the useEffect won't trigger, so manually set it to the
      // previous value here. If the update is valid, the value will change triggering the useEffect overriding this.
      setNewValue(value);
    } else {
      setNewValue(value);
    }
    setShowInput(false);
  };

  const renderValue = (
    <HoverTooltip text={maxDisplayChars !== -1 && value?.length > maxDisplayChars && value}>
      <>
        <p
          id={id}
          data-testid={`valueInput_${testId}`}
          className={classNames('mb0 specEditableText', { 'cursorText editableText': allowEditing }, textClasses)}
          onClick={(e) => {
            if (allowEditing) {
              setInputSize(e.currentTarget);
              setShowInput(true);
            }
          }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.keyCode === KEY_CODES.ENTER && allowEditing) {
              setInputSize(e.currentTarget);
              setShowInput(true);
            }
          }}>
          {maxDisplayChars > -1 ? utilities.intelligentlyTruncate(value, maxDisplayChars) : value}
        </p>
        {/* This <span> interferes with some tests. The Portal places it outside this component */}
        <Portal>
          <span ref={heightRef} className="editableTextHeight">
            1
          </span>
        </Portal>
      </>
    </HoverTooltip>
  );

  const renderEditableText = () => {
    const formControl = (
      <Form.Control
        type="text"
        id={id}
        data-testid={`editableInput_${testId}`}
        className={classNames('specEditableText pr0 pl0', inputClasses)}
        style={{
          width: inputWidth,
          height: inputHeight,
        }}
        bsPrefix="editableTextInput"
        value={newValue}
        size="sm"
        onFocus={(e) => e.target.select()}
        autoFocus={true}
        onChange={(e) => setNewValue(e.target.value)}
        onBlur={updateInput}
        onKeyDown={(e) => e.keyCode === KEY_CODES.ENTER && e.currentTarget.blur()}
      />
    );

    if (autoWidth) {
      return (
        <div className="flexRowContainer">
          {formControl}
          <div ref={inputRef} className={classNames(inputClasses, 'editableTextResizer')}>
            {newValue}
          </div>
        </div>
      );
    }

    return formControl;
  };

  return showInput ? renderEditableText() : renderValue;
};
