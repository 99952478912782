// @ts-strict-ignore
import React, { useRef } from 'react';
import _ from 'lodash';
import { FormControl, InputGroup } from 'react-bootstrap';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { successToast } from '@/utilities/toast.utilities';
import { TextButton } from '@/core/TextButton.atom';

/**
 * Renders an input field with a button to copy the contents of the field to the clipboard.
 */
interface InputSelectOnFocusProps {
  /** an identifier to be used in tests */
  testId: string;
  /** true if the field should be read only, false otherwise */
  readOnly: boolean;
  /** string to display in the tooltip when hovering the input field */
  fieldTooltip: string;
  /** string to display in the tooltip when hovering the button */
  buttonTooltip: string;
  /** value to show in the input field */
  value: string;
  /** notification message sent to user */
  notifyMessage?: string;
  extraButtons?: JSX.Element[];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CopyableInputField: React.FunctionComponent<InputSelectOnFocusProps> = ({
  testId,
  readOnly,
  fieldTooltip,
  buttonTooltip,
  value,
  notifyMessage,
  extraButtons,
  onChange = _.noop,
}) => {
  const inputValue = useRef<HTMLInputElement>(null);

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  const copyToClipboard = () => {
    inputValue.current.select();
    document.execCommand('copy');
    inputValue.current.blur();
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    }

    if (!_.isNil(notifyMessage)) {
      successToast({ messageKey: notifyMessage });
    }
  };

  return (
    <InputGroup>
      <HoverTooltip text={fieldTooltip}>
        <FormControl
          data-testid={testId}
          autoComplete="off"
          type="text"
          readOnly={readOnly}
          ref={inputValue}
          value={value}
          onFocus={onFocus}
          onChange={onChange}
        />
      </HoverTooltip>
      <InputGroup.Append className="copyableInputGroup border-0">
        <InputGroup.Text className="border-0">
          {extraButtons}
          <TextButton
            label=""
            iconStyle="theme"
            icon="fa-copy"
            tooltip={buttonTooltip}
            tooltipOptions={{ placement: 'top' }}
            testId={`${testId}-copyButton`}
            onClick={copyToClipboard}
          />
        </InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  );
};
