import React from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { ButtonVariant, TextButton } from '@/core/TextButton.atom';
import { useTranslation } from 'react-i18next';
import { ButtonWithManagedSpinner } from '@/core/ButtonWithManagedSpinner.atom';

interface ConfirmDeleteModalProps {
  onClose: () => void;
  action: () => Promise<any> | void;
  actionBtnLabel?: string;
  cancelBtnLabel?: string;
  headerTranslationKey?: string;
  headerTranslationParams?: Record<string, string | number>;
  bodyTranslationKey?: string;
  bodyTranslationParams?: Record<string, string | number>;
  confirmBtnVariant?: ButtonVariant;
}

/**
 * Modal dialog with Cancel/Delete buttons and configurable text/actions
 */
export const ConfirmDeleteModal: React.FunctionComponent<ConfirmDeleteModalProps> = (props) => {
  const {
    onClose,
    action,
    headerTranslationKey = '',
    bodyTranslationKey = '',
    actionBtnLabel = '',
    cancelBtnLabel = '',
    confirmBtnVariant = 'danger',
    headerTranslationParams = {},
    bodyTranslationParams = {},
  } = props;
  const { t } = useTranslation();

  return (
    <Modal show={true} onHide={onClose} data-testid="confirmDeleteModal">
      <Modal.Header closeButton={true}>
        <h4>
          {t(_.isEmpty(headerTranslationKey) ? 'DELETE_MODAL.HEADER' : headerTranslationKey, headerTranslationParams)}
        </h4>
      </Modal.Header>
      <Modal.Body>
        <div className="semi-bold">
          {t(_.isEmpty(bodyTranslationKey) ? 'DELETE_MODAL.BODY' : bodyTranslationKey, bodyTranslationParams)}
        </div>
      </Modal.Body>
      <Modal.Footer className="textAlignRight">
        <TextButton
          onClick={onClose}
          label={_.isEmpty(cancelBtnLabel) ? 'CANCEL' : cancelBtnLabel}
          size="sm"
          testId="cancelDeleteBtn"
          extraClassNames="width-100"
          id="cancelDeleteBtn"
        />

        <ButtonWithManagedSpinner
          buttonProps={{
            id: 'confirmDeleteBtn',
            testId: 'confirmDeleteBtn',
            variant: confirmBtnVariant,
            size: 'sm',
            extraClassNames: 'width-100',
          }}
          label={_.isEmpty(actionBtnLabel) ? 'DELETE' : actionBtnLabel}
          action={async () => {
            await action();
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};
