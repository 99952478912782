import React, { useState } from 'react';
import classNames from 'classnames';
import { IconWithSpinner, IconWithSpinnerProps } from '@/core/IconWithSpinner.atom';
import { TextButton, TextButtonProps } from '@/core/TextButton.atom';
import { useTranslation } from 'react-i18next';
import { useIsMounted } from '@/core/hooks/useIsMounted.hook';

export interface ButtonWithManagedSpinnerProps {
  /** click action for button click */
  action: () => Promise<any>;
  /** icon to display on button when not spinning*/
  icon?: string;
  /** text for button */
  label: string;
  /** additional properties for TextButton element */
  buttonProps?: Omit<TextButtonProps, 'onClick'>; // omit onClick since we handle it here
  /** additional properties for IconWithSpinner element */
  spinnerIconProps?: Omit<IconWithSpinnerProps, 'spinning'>; // omit spinning as it's handled in this component
}

/** Button that shows spinner icon while click action is in progress */
export const ButtonWithManagedSpinner: React.FunctionComponent<ButtonWithManagedSpinnerProps> = ({
  action,
  label,
  icon,
  buttonProps,
  spinnerIconProps,
}) => {
  const isMounted = useIsMounted();
  const [spinning, setSpinning] = useState(false);
  const { t } = useTranslation();

  const clickFunction = () => {
    if (buttonProps?.disabled || spinning) {
      return;
    }
    setSpinning(true);
    return action().finally(() => isMounted.current && setSpinning(false));
  };

  return (
    <TextButton
      {...buttonProps}
      onClick={clickFunction}
      disabled={buttonProps?.disabled || spinning}
      formattedLabel={
        <>
          <IconWithSpinner spinning={spinning} icon={icon} {...spinnerIconProps} />
          <span className={classNames({ ml5: spinning || icon })}>{t(label)}</span>
        </>
      }
    />
  );
};
