// @ts-strict-ignore
import React, { useRef, useEffect } from 'react';
import { FormControl, FormControlProps } from 'react-bootstrap';
import { BsPrefixRefForwardingComponent } from 'react-bootstrap/esm/helpers';
import _ from 'lodash';

/**
 * This component can be used interchangable with FormControl.Input
 * and it will fix the issue where the cursor is moved at the end of the text
 * when the user types new characters in the middle of the text
 */
export const FormInput: BsPrefixRefForwardingComponent<'input', FormControlProps> = React.forwardRef<
  HTMLInputElement,
  FormControlProps
>((props, ref: React.MutableRefObject<HTMLInputElement>) => {
  const { value, onChange, ...rest } = props;
  const internalRef = useRef(null);

  const setAllRefs = (receivedRef) => {
    if (ref) ref.current = receivedRef;
    internalRef.current = receivedRef;
  };

  useEffect(() => {
    /**
     * we need to change the value only if it's different since the internal state of "input" will change it anyway
     * this will only be the case when the value has been changed externally via store (undo / redo)
     */
    if (!_.isUndefined(value) && value !== internalRef.current?.value) {
      // we need to use this method because using the value props directly will switch the input to a "controlled"
      // component
      internalRef.current.value = `${value}`;
    }
  }, [value]);

  return <FormControl {...rest} defaultValue={value} onChange={onChange} ref={setAllRefs} />;
});
