// @ts-strict-ignore
/**
 * Cancel and Execute buttons (used in tool panel)
 *
 * @param toolId (string): the id of the tool currently open
 * @param cancelAction (function): function to call when clicking the Cancel button (takes no parameters)
 * @param submitAction (function): function to call when clicking the Execute button (takes no parameters)
 * @param btnDisabled (boolean): true if the Execute button should be disabled
 * @param (optional) hideCancel (boolean): true to hide the cancel button
 * @param (optional) submitBtnLabel (string): the label for the button (Execute is used if nothing is specified)
 * @param (optional) submitBtnId (string): an id for the Execute button
 * @param (optional) extraClassNames (string): extra class names to be placed on the div around the buttons
 */
import React, { useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { sqInvestigateStore } from '@/core/core.stores';
import { ButtonVariant } from '@/core/TextButton.atom';
import { INVESTIGATE_TOOLS, TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { doTrack } from '@/track/track.service';
import { Button } from '@seeqdev/qomponents';
import { useTranslation } from 'react-i18next';

interface CancelAndExecuteProps {
  toolId?: string;
  cancelAction: () => any;
  submitAction: () => void | Promise<void>;
  btnDisabled: boolean;
  hideCancel?: boolean;
  cancelBtnLabel?: string;
  submitBtnLabel?: string;
  submitBtnId?: string;
  submitBtnTestId?: string;
  submitBtnVariant?: ButtonVariant;
  submitBtnFormattedLabel?: JSX.Element;
  submitBtnTooltip?: string;
  extraClassNames?: string;
}

export const CancelAndExecute: React.FunctionComponent<CancelAndExecuteProps> = ({
  toolId = null,
  cancelAction,
  submitAction,
  hideCancel,
  cancelBtnLabel = 'CANCEL',
  submitBtnLabel = 'EXECUTE',
  btnDisabled,
  submitBtnId,
  submitBtnTestId,
  submitBtnVariant = 'theme',
  submitBtnFormattedLabel,
  submitBtnTooltip,
  extraClassNames,
}) => {
  const [inProgress, setInProgress] = useState(false);
  const isTrackable = !_.isNil(toolId);

  // if the periodic condition is used in Organizer Topic for a date range configuration do not use trackAction
  const toolTrackAction =
    toolId === TREND_TOOLS.PERIODIC_CONDITION && submitBtnLabel === 'CONTINUE'
      ? 'Periodic Condition Continue Button'
      : _.get(_.find(INVESTIGATE_TOOLS, ['id', toolId]), 'trackAction');

  const trackAndCancel = () => {
    if (isTrackable) {
      doTrack('Workbench_Tool', toolTrackAction, 'cancelled', true);
    }
    cancelAction();
  };

  const trackAndSubmit = () => {
    setInProgress(true);
    if (isTrackable) {
      doTrack('Workbench_Tool', toolTrackAction, 'executed', true);
    }
    Promise.resolve(submitAction())
      .then(() => setInProgress(sqInvestigateStore.activeTool !== toolId))
      .finally(() => setInProgress(false));
  };
  const { t } = useTranslation();
  return (
    <div className={classNames('flexRowContainer', 'mt5', extraClassNames)}>
      <div className="text-center">
        {!hideCancel && (
          <Button
            onClick={trackAndCancel}
            label={t(cancelBtnLabel)}
            size="sm"
            testId="cancelButton"
            extraClassNames="mr20 min-width 100 width-100 max-width-100"
          />
        )}

        <Button
          onClick={trackAndSubmit}
          disabled={inProgress || btnDisabled}
          size="sm"
          testId={submitBtnTestId ?? 'executeButton'}
          extraClassNames="min-width 100 width-100 max-width-100"
          id={submitBtnId}
          variant={submitBtnVariant}
          icon={inProgress ? 'fa fa-spinner fa-spin fa-pulse fa-lg sq-icon-white mr5' : ''}
          label={submitBtnFormattedLabel || t(submitBtnLabel)}
          tooltip={submitBtnTooltip}
        />
      </div>
    </div>
  );
};
