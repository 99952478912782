// @ts-strict-ignore
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { HelpIcon } from '@/core/Icon.atom';
import { Checkbox as QomponentCheckbox } from '@seeqdev/qomponents';
import { KEY_CODES } from '@/main/app.constants';

interface CheckboxProps {
  id: string;
  isChecked: boolean;
  label: string | React.ReactNode;
  extraLabel?: React.ReactElement;
  onChange?: (event: any) => void;
  onClick?: (event: any) => void;
  value?: any;
  type?: 'radio' | 'checkbox';
  disabled?: boolean;
  classes?: string;
  tooltip?: string;
  description?: string;
  skipMemo?: boolean;
  descriptionClasses?: string;
  onHelpIconClick?: () => any;
}

export const CheckboxUnwrapped: React.FunctionComponent<CheckboxProps> = (props) => {
  const {
    onChange = _.noop,
    isChecked,
    label,
    extraLabel,
    id,
    disabled,
    type = 'checkbox',
    classes,
    tooltip,
    description,
    value,
    descriptionClasses = 'ml20 pl2 mtn5 width-200 font-size-smaller',
    onHelpIconClick,
    onClick,
  } = props;

  const { t } = useTranslation();

  return (
    <span
      className={classNames(
        'flexColumnContainer',
        'flexAlignCenter',
        classes,
        isChecked ? 'specChecked' : 'specNotChecked',
      )}
      data-testid={id}>
      <span className="flexColumnContainer flexAlignCenter sq-checkbox">
        <QomponentCheckbox
          value={value}
          onKeyDown={(e) => e.keyCode === KEY_CODES.ENTER && e.currentTarget.click()}
          extraClassNames="mb3"
          onChange={onChange}
          onClick={onClick}
          checked={isChecked}
          type={type}
          id={id}
          testId={`${id}-input`}
          disabled={disabled}
          label={typeof label === 'string' ? t(label) : label}
        />
        {extraLabel}
      </span>
      {tooltip && (
        <HelpIcon tooltip={tooltip} extraClassNames="pl5" onClick={onHelpIconClick} testId={`${id}Tooltip`} />
      )}
      {description && (
        <div className={classNames('text-italic sq-fairly-dark-gray', descriptionClasses)}>{t(description)}</div>
      )}
    </span>
  );
};

/**
 * Basic checkbox
 */
export const Checkbox = React.memo(
  CheckboxUnwrapped,
  (prev, next) =>
    !(
      prev.skipMemo ||
      prev.isChecked !== next.isChecked ||
      prev.label !== next.label ||
      prev.extraLabel !== next.extraLabel ||
      !_.isEqual(prev.value, next.value) ||
      prev.disabled !== next.disabled ||
      prev.classes !== next.classes
    ),
);
