import React from 'react';
import { OverlayTrigger, OverlayTriggerProps, Popover } from 'react-bootstrap';
import { Icon } from '@/core/Icon.atom';

export interface IconWithPopoverTooltipProps extends Omit<OverlayTriggerProps, 'overlay' | 'children'> {
  title?: string | React.ReactNode;
  content: React.ReactNode;
  testId?: string;
}

/**
 * Icon with a customizable/flexible popover
 */
export const IconWithPopoverTooltip: React.FunctionComponent<IconWithPopoverTooltipProps> = ({
  content,
  title,
  testId = 'popover',
  ...restOverlayTriggerProps
}) => {
  return (
    <OverlayTrigger
      {...restOverlayTriggerProps}
      overlay={
        <Popover id={testId} data-testid={testId} className="text-opaque">
          {title && <Popover.Title>{title}</Popover.Title>}
          <Popover.Content>{content}</Popover.Content>
        </Popover>
      }>
      <Icon icon="fa-question-circle" testId={`${testId}Icon`} large={true} extraClassNames="pt3" />
    </OverlayTrigger>
  );
};
