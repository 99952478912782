// @ts-strict-ignore
import React, { useEffect } from 'react';
import { FormGroup } from 'react-bootstrap';
import { DURATION_TIME_UNITS, DurationTimeUnit } from '@/main/app.constants';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Select } from '@seeqdev/qomponents';

const SUPPORTED_VALUES_BY_UNIT = {
  'DURATION_UNITS.SECONDS': [5, 10, 15, 30],
  'DURATION_UNITS.MINUTES': [1, 5, 10, 15, 30],
  'DURATION_UNITS.HOURS': [1, 2, 4, 6, 8, 12],
};
const SUPPORTED_UNIT_LABELS = Object.keys(SUPPORTED_VALUES_BY_UNIT);
const SUPPORTED_UNITS = _.filter(DURATION_TIME_UNITS, (unit) => SUPPORTED_UNIT_LABELS.includes(unit.translationKey));

interface DurationSelectorProps {
  unit: DurationTimeUnit;
  value: number;
  onChange: (value: number, unit: DurationTimeUnit) => void;
  onInvalidInput: (message: string) => void;
}

type Option = { value: string; label: string };
/**
 * Select time durations via dropdowns, with fixed set of options
 */
export const DurationSelector: React.FunctionComponent<DurationSelectorProps> = (props) => {
  const { unit, value, onChange, onInvalidInput } = props;
  const { t } = useTranslation();

  useEffect(() => {
    const validOptions = SUPPORTED_VALUES_BY_UNIT[unit.translationKey];
    if (!validOptions?.includes(value)) {
      onInvalidInput('Unable to parse the schedule');
    }
  });

  const onChangeValue = (option: Option) => {
    const newVal = _.toInteger(option.value);
    onChange(newVal, unit);
  };

  const onChangeUnit = (option: Option) => {
    const newUnit = DURATION_TIME_UNITS.find((unit) => unit.translationKey === option.value);
    const newValueOptions = SUPPORTED_VALUES_BY_UNIT[newUnit.translationKey];
    let newValue = value;
    if (!newValueOptions?.includes(value)) {
      newValue = newValueOptions[0];
    }
    onChange(newValue, newUnit);
  };
  const durationValues = _.map(SUPPORTED_VALUES_BY_UNIT[unit.translationKey], (value) => ({ value, label: value }));
  const unitValues = _.map(SUPPORTED_UNITS, (unit) => ({ value: unit.translationKey, label: t(unit.translationKey) }));
  return (
    <FormGroup className="flexColumnContainer">
      <span data-testid="duration-selector__value">
        <Select
          extraClassNames="width-100 mr5"
          value={_.find(durationValues, { value })}
          onChange={onChangeValue}
          options={durationValues}
          menuPortalTarget={document.body}
        />
      </span>
      <span data-testid="duration-selector__unit">
        <Select
          extraClassNames="width-100"
          value={_.find(unitValues, { value: unit?.translationKey })}
          onChange={onChangeUnit}
          options={unitValues}
          menuPortalTarget={document.body}
        />
      </span>
    </FormGroup>
  );
};
